import React from "react";
import { FaDownload } from 'react-icons/fa';
import './DownloadResume.css'
  
const DownloadResume = () => {
    return (
        <a className="download-resume resume-text resume-button flex" href="resume-luca-luna.pdf">
            <span className="resume-text">Resume</span>
            <FaDownload className="resume-icon" />
        </a>
    );
};
  
export default DownloadResume;