import React from 'react';
import './Education.css'; // Import custom CSS for additional styling

const Experience = () => {
  const experienceItems = [
    {
      title: 'Application Developer',
      company: 'Washington State Department of Ecology',
      duration: 'June 2024 - Present',
      description: 'Contributed to Ecology Administration of Grants and Loans modernization project',
      description1: 'System responsible for managing over $1 billions dollars in funds through the lifetime of award for recipients across Washington State',
      description2: 'Collaborated with SaaS vendors to deliver consistent high quality improvements to the Intelligrants (IGX) system',
      description3: 'Ran UAT and regression testing campaigns with the Ecology business team'
    },
    {
      title: 'Software Development Engineer Intern',
      company: 'Seal and Design',
      duration: 'May 2022 - July 2023',
      description: 'Developed ERP software solutions to enhance all aspects of company performance and client experience,',
      description1: 'created reports, tools, and complex queries to help managers maintain on-time shipments, and track sales',
      description2: 'Contributed to safety, human resources, IT operations, and sales portals'
    },
    {
      title: 'Undergraduate Teaching Assistant',
      company: 'CSE220 Systems Programming',
      duration: 'August 2021 - May 2023',
      description1: 'Headed weekly lab sections to guide students through course exercises',
      description2: 'Held office hours to coach students with course material',
    },
    {
      title: 'Web Developer Intern',
      company: 'Shelfy',
      duration: 'May 2015 - August 2015',
      description: 'Developed backend for webpages which helped connect clients with contractors, contractors with specialists',
      description1: 'and manage/track project progress'
    },
    {
      title: 'Bartender',
      company: 'Ricks on Main',
      duration: 'August 2019 - Present',
      description: 'Developed cocktail menus, maintained customer relationships, delivered high-quality dining experience',
      description1: 'in a fast-paced fine dining environment'
    },
    {
      title: 'Bar Manager',
      company: 'Mambrino King',
      duration: 'June 2018 - August 2019',
      description: 'Developed cocktail menus, hired and trained new employees, managed inventory ordering, and tracked sales',
    },
    {
      title: 'Sous Chef',
      company: 'Arriba Tortilla',
      duration: 'August 2015 - June 2018',
      description: 'Maintained food menu, hired and trained new employees, managed inventory ordering, led a team through daily kitchen operations',
    },
  ];

  return (
    <div id="education "className='container-fluid education'>
      <div className="container">
      <div className="row">
        <div className="col-md-3">
          <h2 className="section-title">Experience</h2>
        </div>
        <div className="col-md-9">
          {experienceItems.map((item, index) => (
            <div className="card mb-4 experience-card" key={index}>
              <div className="card-body">
                <h5 className="card-title">{item.title}</h5>
                <h6 className="card-subtitle mb-2 text-muted">{item.company}</h6>
                <p className="card-text">{item.duration}</p>
                <p className="card-text">{item.description}</p>
                <p className="card-text">{item.description1}</p>
                <p className="card-text">{item.description2}</p>
                <p className="card-text">{item.description3}</p>
              </div>
            </div>
          ))}
        </div>
        <hr className='divider'/>
      </div>
    </div>
    </div>
  );
};

export default Experience;
