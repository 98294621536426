import React from 'react';
import { FaMapMarkerAlt, FaBirthdayCake, FaGlobeAmericas, FaMotorcycle, FaUniversity, FaBriefcase } from 'react-icons/fa';
import './About.css';

const About = () => {
  const aboutItems = [
    { label: 'Location', text: 'Seattle, WA', Icon: FaMapMarkerAlt },
    { label: 'Age', text: '29', Icon: FaBirthdayCake },
    { label: 'Nationality', text: 'U.S.A', Icon: FaGlobeAmericas },
    { label: 'Interests', text: 'Motorcycles, Music, Farming, Travel', Icon: FaMotorcycle },
    { label: 'Study', text: 'The State University of New York at Buffalo', Icon: FaUniversity },
    { label: 'Employment', text: 'Washington State Department of Ecology', Icon: FaBriefcase },
  ];

  const halfLength = Math.ceil(aboutItems.length / 2);
  const leftItems = aboutItems.slice(0, halfLength);
  const rightItems = aboutItems.slice(halfLength);

  return (
    <div id="about" className="about container-fluid">
      <div className="row d-flex align-items-center mt-5 mb-5">
        <div className="col-md-4 d-flex align-items-center justify-content-center">
          <img src="./images/headshot.jpg" alt="Profile" className="img-fluid rounded-circle" />
        </div>
        <div className="col-md-8">
          <div className="row">
            <div className="col-md-4 mt-3">
              <ul className="about-list">
                {leftItems.map(({ label, text, Icon }, idx) => (
                  <li className="about-item" key={idx}>
                    {Icon && <Icon className="about-icon" />}
                    <div>
                      <span className="about-label">{label}:</span>
                      <span className="about-text">{text}</span>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
            <div className="col-md-8 mt-3">
              <ul className="about-list">
                {rightItems.map(({ label, text, Icon }, idx) => (
                  <li className="about-item" key={idx}>
                    {Icon && <Icon className="about-icon" />}
                    <div>
                      <span className="about-label">{label}:</span>
                      <span className="about-text">{text}</span>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
