import React from 'react';
import './WelcomeBlurb.css';
import { FaGithub, FaInstagram, FaLinkedin } from 'react-icons/fa';
import DownloadResume from './DownloadResume'

const WelcomeBlurb = () => {
	return (
	<div className="welcome-blurb">
		<div className="welcome-blurb-box">
			<h2>Hello! I am Luca Luna.</h2>
			<p>
				I am a software developer currently working on grants and loans software for the Washington State Department of Ecology.
			</p>
			<p>
				Prior to becoming a software developer I had an enjoyable career in the restaurant industry working as a bartender and sous chef.
				In my free time I enjoy traveling and have been lucky to visit 13 countries around the world.
			</p>
			<div className="social-links">
				<a href="https://github.com/luca-luna">
				<FaGithub />
				</a>
				<a href="https://www.instagram.com/lwluna/">
				<FaInstagram />
				</a>
				<a href="https://www.linkedin.com/in/luca-luna/">
				<FaLinkedin />
				</a>
			</div>
			<div>
				<DownloadResume />
			</div>
		</div>
	</div>
	);
};

export default WelcomeBlurb;
