import React from 'react';
import './Resume.css'; // Import custom CSS for styling

const Resume = () => {
    const education = [
        {
        degree: 'Bachelor of Science in Computer Science',
        institution: 'The State University of New York at Buffalo',
        graduated: '2023',
        honors: 'Cum Laude',
        description:    `Gained fundamental engineering and software development skills through B.S Computer Science curriculum. Built a
                        strong foundation in algorithms, data structures, databases, systems, and web development.`
        }
    ];

    const experience = [
        {
          title: 'DevOps Engineer Intern',
          company: 'Seal and Design',
          location: 'Clarence, NY',
          duration: 'May 2023 - Present',
          description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        },
        {
            title: 'Undergraduate Teaching Assistant',
            course: 'CSE220 Systems Programming',
            location: 'Buffalo, NY',
            duration: 'August 2021 - May 2023',
            description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
          },
        // Add more experience items as needed
      ];


return (
    <div id="resume" className="resume container-fluid">
        <div className="row d-flex section-custom-border">
            <div className="col-md-3 d-flex align-items-center justify-content-center">
                <h3 className='title-custom-border'>Education</h3>
            </div>
            <div className="col-md-9 mt-5">
                <div className="row">
                    <div className="col-md-9 mt-3">
                    <ul>
                    {education.map((item, index) => (
                        <li key={index}>
                        {Object.entries(item).map(([key, value]) => (
                            <div className='row d-flex'>
                                <div className='col-md-3'>
                                    <strong>{key}</strong>
                                </div>
                                <div className='col-md-9'>
                                    <p>{value}</p>
                                </div>
                            </div>
                        ))}
                        </li>
                    ))}
                    </ul>
                    </div>
                </div>
            </div>
            </div>
            <div className="row d-flex align-items-center section-custom-border">
                <div className="col-md-3 d-flex align-items-center justify-content-center">
                    <h3 className='title-custom-border'>Experience</h3>
                </div>
                <div className="col-md-9">
                    <div className="row">
                        <div className="col-md-9 mt-3">
                        <ul>
                        {experience.map((item, index) => (
                            <li key={index} className="pb-3">
                            {Object.entries(item).map(([key, value]) => (
                                <div className='row d-flex'>
                                <div className='col-md-3'>
                                    <strong>{key}</strong>
                                </div>
                                <div className='col-md-9'>
                                    <p>{value}</p>
                                </div>
                            </div>
                            ))}
                            </li>
                        ))}
                        </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row d-flex align-items-center section-custom-border">
                <div className="col-md-3 d-flex align-items-center justify-content-center">
                    <h3 className='title-custom-border'>Skills</h3>
                </div>
                <div className="col-md-9">
                    <div className="row">
                        <div className="col-md-9 mt-3">
                        <ul>
                        {experience.map((item, index) => (
                            <li key={index}>
                            {Object.entries(item).map(([key, value]) => (
                                <div className='row d-flex'>
                                <div className='col-md-3'>
                                    <strong>{key}</strong>
                                </div>
                                <div className='col-md-9'>
                                    <p>{value}</p>
                                </div>
                            </div>
                            ))}
                            </li>
                        ))}
                        </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
  );
};

export default Resume;
