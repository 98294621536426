import React from 'react';
import './Skills.css';
import './Education.css';

const Skills = () => {
  return (
    <div id="skills" className="container-fluid education">
      <div className="row">
        <div className="col-md-3">
          <h2 className="section-title">Skills</h2>
        </div>
        <div className="col-md-9">
          <div className="row">
            <div className="col-md-6">
              <div className="card">
                <div className="skill-section card-body">
                  <h3 className="card-title">Frontend</h3>
                  <ul>
                    <li>HTML</li>
                    <li>CSS</li>
                    <li>SCSS</li>
                    <li>JavaScript</li>
                    <li>React</li>
                  </ul>
                </div>
              </div>
              <div className="card">
                <div className="skill-section card-body">
                  <h3 className="card-title">Backend</h3>
                  <ul>
                    <li>C</li>
                    <li>C++</li>
                    <li>Golang</li>
                    <li>Python</li>
                    <li>MIPS</li>
                    <li>PHP</li>
                    <li>Node.js</li>
                    <li>Express.js</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="card">
                <div className="skill-section card-body">
                  <h3 className="card-title">Database Management</h3>
                  <ul>
                    <li>SQL</li>
                    <li>MySQL</li>
                    <li>MongoDB</li>
                    <li>PostgreSQL</li>
                    <li>SSMS</li>
                  </ul>
                </div>
              </div>
              <div className="card">
                <div className="skill-section card-body">
                  <h3 className="card-title">Tools</h3>
                  <ul>
                    <li>Git</li>
                    <li>Docker</li>
                    <li>Atlasian Suit</li>
                    <li>VS Code</li>
                    <li>Digital Ocean</li>
                    <li>Nginx</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Skills;
