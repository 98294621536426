import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import LandingPage from './components/LandingPage';
import About from './components/About';
import Resume from './components/Resume';
import Experience from './components/Experience.js';
import Education from './components/Education';
import Skills from './components/Skills';
import ContactForm from './components/ContactForm';
import Footer from './components/Footer'

const App = () => {
	return (
		<div className='App'>
			<LandingPage />
			<About />
			<Education />
			<Experience />
			<Skills />
			<ContactForm />
			<Footer />
		</div>
	);
};

export default App;
