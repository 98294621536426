import React from 'react';
import './Education.css';

const Education = () => {
  const educationItems = [
    {
      institution: 'The State University of New York at Buffalo',
      degree: 'Bachelor of Science in Computer Science',
      duration: 'May 2023',
      honors: 'Cum Laude'
    }
  ];

  return (
    <div id="education" className="container-fluid education">
      <div className='container'>
      <div className="row">
        <div className="col-md-3">
          <h2 className="section-title">Education</h2>
        </div>
        <div className="col-md-9">
          {educationItems.map((item, index) => (
            <div className="card mb-4 education-card" key={index}>
              <div className="card-body">
                <h5 className="card-title">{item.institution}</h5>
                <h6 className="card-subtitle mb-2 text-muted">{item.degree}</h6>
                <p className="card-text mb-1">{item.duration}</p>
                <p className="card-text">{item.honors}</p>
              </div>
            </div>
          ))}
        </div>
        <hr className='divider'/>
      </div>
      </div>
    </div>
  );
};

export default Education;
