import React, { useState } from 'react';
import './ContactForm.css';
import { FaGithub, FaInstagram, FaLinkedin } from 'react-icons/fa';
import axios from 'axios';

const ContactForm = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [isSent, setIsSent] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      //await axios.post('http://localhost:3001/sendEmail', { name, email, message });


      await axios.post('https://lucaluna.tech/sendEmail', { name, email, message });
      //await axios.post('http://127.0.0.1:3001/test', { name, email, message });
      //await axios.post('http://lucaluna.tech:3001/sendEmail', { name, email, message });
      //await axios.post('http://localhost:3001/sendEmail', { name, email, message });
      //await axios.post('http://lucaluna.tech:3001/sendEmail', { name, email, message });
      //await axios.post('https://lucaluna.tech:3001/sendEmail', { name, email, message });
      setIsSent(true);
      // Reset form values after successful submission
      setName('');
      setEmail('');
      setMessage('');
      setTimeout(() => {
        setIsSent(false);
      }, 1500); // Display success message for 3 seconds
    } catch (error) {
      console.log('Failed to send email:', error);
    }
  };

  return (
    <div id="contact" className="contact-container">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-7">
            <div className="row">
              <div className="col-md-6">
                <div className="contact-message">
                  <h2>Contact Me</h2>
                  <p>If you have any questions or would like to discuss a project, feel free to reach out to me.</p>
                  <div className="social-links">
                    <a href="https://github.com/luca-luna">
                      <FaGithub />
                    </a>
                    <a href="https://www.instagram.com/lwluna/">
                      <FaInstagram />
                    </a>
                    <a href="https://www.linkedin.com/in/luca-luna/">
                      <FaLinkedin />
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="contact-form">
                  {isSent ? (
                    <div className="success-message">
                      <p>Your email has been sent successfully!</p>
                    </div>
                  ) : (
                    <form onSubmit={handleSubmit}>
                      <div className="form-group">
                        <label htmlFor="name">Name</label>
                        <input
                          type="text"
                          className="form-control"
                          id="name"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          required
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <input
                          type="email"
                          className="form-control"
                          id="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          required
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="message">Message</label>
                        <textarea
                          className="form-control"
                          id="message"
                          rows="5"
                          value={message}
                          onChange={(e) => setMessage(e.target.value)}
                          required
                        ></textarea>
                      </div>
                      <button type="submit" className="btn btn-primary">
                        Send Message
                      </button>
                    </form>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
